import React, { FunctionComponent, useEffect } from 'react'
import PropTypes from 'prop-types'

import Header from './basics/header'
import Footer from './basics/footer'
import '../styles/layout.scss'
import { revealAnimations } from '../utils/reveal'

const Layout: FunctionComponent = ({ children }) => {
    useEffect(() => {
        revealAnimations()
    }, [])

    return (
        <>
            <Header />
            <main>
                <div className="main-grid">{children}</div>
            </main>
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
