export const reveal: Record<string, string> = {
    'data-sal': 'fade',
    'data-sal-duration': '1000',
    'data-sal-delay': '400',
}

export function revealAnimations() {
    document.querySelectorAll('h1').forEach((item) => {
        item.setAttribute('data-sal', 'slide-down')
        item.setAttribute('data-sal-duration', '400')
        item.setAttribute('data-sal-delay', '300')
    })

    document.querySelectorAll('h2').forEach((item) => {
        item.setAttribute('data-sal', 'fade')
        item.setAttribute('data-sal-duration', '400')
        item.setAttribute('data-sal-delay', '300')
    })

    document.querySelectorAll('.kicker').forEach((item) => {
        item.setAttribute('data-sal', 'fade')
        item.setAttribute('data-sal-duration', '400')
        item.setAttribute('data-sal-delay', '100')
    })

    document
        .querySelectorAll('.articles .carousel__slide')
        .forEach((item, index) => {
            item.setAttribute('data-sal', 'fade')
            item.setAttribute('data-sal-duration', '400')
            item.setAttribute('data-sal-delay', String(400 + index * 100))
        })

    document.querySelectorAll('figure').forEach((item) => {
        item.setAttribute('data-sal', 'fade')
        item.setAttribute('data-sal-duration', '400')
        item.setAttribute('data-sal-delay', '200')
    })
    document.querySelectorAll('.image-3 figure').forEach((item) => {
        item.setAttribute('data-sal-delay', '600')
    })

    document.querySelectorAll('.text').forEach((item) => {
        item.setAttribute('data-sal', 'fade')
        item.setAttribute('data-sal-duration', '400')
        item.setAttribute('data-sal-delay', '200')
    })
    document.querySelectorAll('figure + .text').forEach((item) => {
        item.setAttribute('data-sal-delay', '600')
    })
    document.querySelectorAll('.text + figure').forEach((item) => {
        item.setAttribute('data-sal-delay', '600')
    })

    document.querySelectorAll('.tags li').forEach((item, index) => {
        item.setAttribute('data-sal', 'slide-top')
        item.setAttribute('data-sal-duration', '400')
        item.setAttribute('data-sal-delay', String(400 + index * 50))
    })

    document.querySelectorAll('.logo-carousel li').forEach((item, index) => {
        item.setAttribute('data-sal', 'slide-top')
        item.setAttribute('data-sal-duration', '500')
        item.setAttribute('data-sal-delay', String(500 + index * 50))
    })

    document.querySelectorAll('.we-are-hiring .job').forEach((item, index) => {
        item.setAttribute('data-sal', 'fade')
        item.setAttribute('data-sal-duration', '400')
        item.setAttribute('data-sal-delay', String(400 + index * 50))
    })
}
