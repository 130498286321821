import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const SideMenu = ({ show, onToggle }) => {
    return (
        <SideMenuContainer className={show && 'show'}>
            <nav>
                <Link onClick={onToggle} to="/">
                    Faible
                </Link>
                <Link onClick={onToggle} to="/projekte">
                    Projekte
                </Link>
                <Link onClick={onToggle} to="/artikel">
                    Artikel
                </Link>
                <Link onClick={onToggle} to="/karriere">
                    Karriere
                </Link>
                <Link onClick={onToggle} to="/kontakt">
                    Kontakt
                </Link>
            </nav>
            <div className="contact">
                <p>
                    <strong>So erreichen sie uns</strong>
                    <br />
                    <a href="tel:+4934139298780">+49 341 39 29 87 80</a>
                    <br />
                    <a href="mailto:#">Jetzt eine Mail schreiben</a>
                </p>
            </div>
        </SideMenuContainer>
    )
}

const SideMenuContainer = styled.div`
    height: 100vh;
    width: 100%;
    max-width: 18rem;
    padding: 0 3rem 0 2.5rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    background-color: ${(props) => props.theme.colorGrayLight};
    font-family: 'SawtonBauhaus';
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    opacity: 0;
    transform: translate(100%, 0);
    transition: all 0.15s ease-in;

    @media (min-width: ${(props) => props.theme.md}) {
        max-width: 22.222rem;
        padding: 0 3rem 0 4rem;
    }

    &.show {
        transform: translate(0, 0);
        opacity: 1;
    }

    nav {
        display: flex;
        flex-direction: column;
        margin-top: 7rem;

        @media (min-width: ${(props) => props.theme.md}) {
            margin-top: 7.5rem;
        }

        a {
            width: max-content;
            color: ${(props) => props.theme.colorPrimary};
            font-size: 1.8rem;
            margin-bottom: 1.875rem;

            @media (min-width: ${(props) => props.theme.md}) {
                top: 2rem;
                right: 3rem;
                margin-bottom: 1.7rem;
            }
        }
    }

    .contact {
        margin-top: 6rem;

        @media (max-height: 615px) {
            margin-top: 3rem;
        }

        @media (min-width: ${(props) => props.theme.md}) {
            margin-top: 10.4rem;
        }

        p {
            color: ${(props) => props.theme.colorPrimary};

            a {
                color: ${(props) => props.theme.colorPrimary};

                &[href*='mailto'] {
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
`

export default SideMenu
