import React, { useContext } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { isType } from '../../utils/typecheck'
import Arrow from '../../images/arrow.inline.svg'

export default function Footer() {
    return (
        <FooterContainer>
            <div className="row first">
                <div className="nav">
                    <Link to="/">Faible</Link>
                    <Link to="/projekte">Projekte</Link>
                    <Link to="/artikel">Artikel</Link>
                    <Link to="/karriere">Karriere</Link>
                    <Link to="/kontakt">Kontakt</Link>
                </div>
                <div className="company">
                    <p>
                        <strong>Faible Gmbh</strong>
                        <br />
                        Marienplatz 1, 04103 Leipzig
                        <br />
                        <a href="tel:+4934139298780">+49 341 39 29 87 80</a>
                    </p>
                    <p>
                        <strong>Schreiben Sie uns</strong>
                        <br />
                        <a href="mailto:#">Jetzt eine Mail schreiben</a>
                    </p>
                    <p>
                        <strong>Faible auf LinkedIn</strong>
                        <br />
                        <a href="#">Jetzt vernetzen</a>
                    </p>
                </div>
                <div className="magazine">
                    <p>
                        <strong>Aus unserem Magazin voller Ideen</strong>
                    </p>
                    <StaticQuery
                        query={graphql`
                            query magazinContent {
                                allAirtable(
                                    filter: {
                                        table: { in: ["Projekte", "Artikel"] }
                                    }
                                    sort: { order: ASC, fields: rowIndex }
                                ) {
                                    group(field: data___Slug) {
                                        nodes {
                                            table
                                            data {
                                                Type
                                                Content
                                                Slug
                                            }
                                        }
                                    }
                                }
                            }
                        `}
                        render={(data) =>
                            data.allAirtable.group.map((item, index) => {
                                if (index < 3) {
                                    let contentItem = {}
                                    contentItem.index = index
                                    contentItem.link = `/${item.nodes[0].table.toLowerCase()}/${
                                        item.nodes[0].data.Slug
                                    }`
                                    item.nodes.map((textItem) => {
                                        switch (true) {
                                            case isType(
                                                textItem.data.Type,
                                                'H1'
                                            ):
                                                contentItem.title =
                                                    textItem.data.Content
                                        }
                                    })
                                    return (
                                        <Link
                                            key={contentItem.index}
                                            to={contentItem.link}
                                        >
                                            {contentItem.title}
                                        </Link>
                                    )
                                }
                            })
                        }
                    />
                </div>
            </div>
            <div className="row second">
                <div className="copyright">© 2021 Faible GmbH</div>
                <div className="scroll-top">
                    <button
                        className="button arrow top color-white"
                        onClick={() => window.scrollTo(0, 0)}
                    >
                        <Arrow />
                    </button>
                </div>
                <div className="legal-links">
                    <Link to="/impressum">Impressum</Link>
                    <Link to="/datenschutz">Datenschutz</Link>
                    <Link to="/agb">AGB</Link>
                </div>
            </div>
        </FooterContainer>
    )
}

const FooterContainer = styled.footer`
    max-width: ${(props) => props.theme.contentWidth};
    margin: auto;
    padding: 10rem 0 7rem 0;
    font-family: 'SawtonBauhaus';

    .row {
        display: grid;
        grid-template-columns: repeat(25, 1fr);
        font-size: 0.888rem;

        &.first {
            .nav {
                display: none;
                line-height: 1.5;

                @media (min-width: ${(props) => props.theme.lg}) {
                    grid-column: 3 / span 2;
                    display: block;
                }

                a {
                    display: block;
                    margin-bottom: 0.5rem;

                    &[aria-current='page'] {
                        text-decoration: underline;
                    }
                }
            }

            .company {
                grid-column: 3 / -3;

                @media (min-width: ${(props) => props.theme.md}) {
                    grid-column: 5 / -5;
                }

                @media (min-width: ${(props) => props.theme.lg}) {
                    grid-column: 6 / span 6;
                }

                a {
                    &:not([href*='tel']) {
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .magazine {
                display: none;

                @media (min-width: ${(props) => props.theme.lg}) {
                    grid-column: 17 / -3;
                    display: block;
                }

                a {
                    display: block;
                    margin-top: 1rem;
                }
            }
        }

        &.second {
            margin-top: 6rem;
            row-gap: 2rem;

            .copyright {
                grid-column: 3 / -3;
                grid-row: 2;
                opacity: 0.5;
                letter-spacing: 0.8px;

                @media (min-width: ${(props) => props.theme.md}) {
                    grid-column: 5 / -5;
                }

                @media (min-width: ${(props) => props.theme.lg}) {
                    grid-column: 3 / span 4;
                    grid-row: auto;
                }
            }

            .scroll-top {
                display: none;

                @media (min-width: ${(props) => props.theme.lg}) {
                    grid-column: 13 / span 1;
                    display: block;
                }
            }

            .legal-links {
                grid-column: 3 / -3;

                @media (min-width: ${(props) => props.theme.md}) {
                    grid-column: 5 / -5;
                }

                @media (min-width: ${(props) => props.theme.lg}) {
                    grid-column: 17 / -3;
                }

                a {
                    margin-right: 2rem;
                }
            }
        }
    }
`
