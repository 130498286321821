import React, { useContext, useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import SideMenu from './sideMenu'

export default function Header() {
    const [showSideMenu, setShowSideMenu] = useState(false)

    function toggleSideMenu() {
        setShowSideMenu(!showSideMenu)
        document.body.classList.toggle('fixed')
    }

    return (
        <>
            <HeaderContainer>
                <Link to="/">
                    <img src="/Faible_Logo.svg" alt="" />
                </Link>
                <div className="nav-toggle">
                    <button
                        onClick={toggleSideMenu}
                        className={
                            !showSideMenu
                                ? 'button open show-me'
                                : 'button open'
                        }
                    >
                        <div></div>
                        <div></div>
                    </button>
                    <button
                        className={
                            showSideMenu
                                ? 'button close show-me'
                                : 'button close'
                        }
                        onClick={toggleSideMenu}
                    >
                        &times;
                    </button>
                </div>
            </HeaderContainer>
            {showSideMenu && <CloseAside onClick={toggleSideMenu}></CloseAside>}
            <SideMenu show={showSideMenu} onToggle={toggleSideMenu} />
        </>
    )
}

const HeaderContainer = styled.header`
    margin: auto;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    @media (min-width: ${(props) => props.theme.md}) {
        padding: 2.1rem 2.6rem 2rem 2.2rem;
    }

    img {
        width: 3rem;
        height: auto;
    }

    .nav-toggle {
        position: relative;
        width: 2rem;
        height: 2rem;
        z-index: 15;

        button {
            position: absolute;
            color: ${(props) => props.theme.colorWhite};
            opacity: 0;
            transform: scale(2, 0.5);
            transition: all 0.15s ease-in;

            &.show-me {
                transform: scale(1, 1);
                opacity: 1;
            }
        }

        .open {
            height: 2rem;
            div {
                height: 2px;
                width: 1.6rem;
                background-color: ${(props) => props.theme.colorWhite};

                &:first-of-type {
                    margin-bottom: 0.5rem;
                }
            }
        }

        .close {
            height: 2rem;
            font-size: 2.5rem;
            line-height: 1rem;
            font-weight: 700;
            color: ${(props) => props.theme.colorBlack};
        }
    }
`

const CloseAside = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`
